<template>
  <div>
    <v-app-bar dense dark color="primary accent-3" app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <img src="./../assets/full-logo.png" :height="appBarHeight" />
      <v-spacer></v-spacer>
      <full-text-search />
      <full-text-search-hint />
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" bottom temporary app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> FidataPro </v-list-item-title>
          <v-list-item-subtitle> version 1.0.47 </v-list-item-subtitle>
          <v-list-item-subtitle>{{ userName }} <icon-button class="m-0 p-0" icon="mdi-account-key" tooltip="View roles & permissions" @click="showRolesPermissionsDialog"></icon-button></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense nav>
        <nav-menu-item link="/" title="Home" icon="mdi-home"></nav-menu-item>

        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>Practice Management</v-list-item-title>
          </template>
          <nav-menu-item
            v-for="item in filterNoAccessMenuItems(practiceManagementMenuItems)"
            :key="item.title"
            :link="item.link"
            :title="item.title"
            :icon="item.icon"
          ></nav-menu-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>Accounting</v-list-item-title>
          </template>
          <nav-menu-item
            v-for="item in filterNoAccessMenuItems(accountingMenuItems)"
            :key="item.title"
            :link="item.link"
            :title="item.title"
            :icon="item.icon"
          ></nav-menu-item>
        </v-list-group>
        <v-list-group v-if="canViewReport">
          <template v-slot:activator>
            <v-list-item-title>Reports</v-list-item-title>
          </template>
          <nav-menu-item
            v-for="item in filterNoAccessMenuItems(reportMenuItems)"
            :key="item.title"
            :link="item.link"
            :title="item.title"
            :icon="item.icon"
          ></nav-menu-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>System Admin</v-list-item-title>
          </template>
          <nav-menu-item
            v-for="item in filterNoAccessMenuItems(systemAdminMenuItems)"
            :key="item.title"
            :link="item.link"
            :title="item.title"
            :icon="item.icon"
          ></nav-menu-item>
        </v-list-group>
        <nav-menu-item link="/sign_out" title="Sign Out" icon="mdi-account"></nav-menu-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { permission } from "../framework/permission.js"
import FullTextSearch from "../components/FullTextSearch.vue"
import FullTextSearchHint from "../components/FullTextSearchHint.vue"
import NavMenuItem from "../components/NavMenuItem.vue"
import { getCookieData } from "../store/commonStorage.js"
import IconButton from "./common/buttons/IconButton.vue"
import eventBus from "@/framework/eventBus.js"

export default {
  name: "Navigation",
  components: {
    FullTextSearch,
    FullTextSearchHint,
    NavMenuItem,
    IconButton,
  },
  data() {
    return {
      drawer: false,
      group: null,
      systemAdminMenuItems: [
        {
          title: "Users",
          icon: "mdi-account-group",
          permission: [permission.viewUsers],
          link: "/users",
        },
        {
          title: "Security Roles",
          icon: "mdi-shield-account",
          permission: [permission.viewSecurityRoles],
          link: "/security_roles",
        },
        {
          title: "Word Templates",
          icon: "mdi-microsoft-word",
          permission: [permission.viewWordTemplates],
          link: "/word_templates",
        },
        {
          title: "Disbursement List",
          icon: "mdi-alarm-panel-outline",
          permission: [permission.viewTypeOfDisbursement],
          link: "/type_of_disbursements",
        },
        {
          title: "Branches",
          icon: "mdi-office-building",
          permission: [permission.viewBranches],
          link: "/branches",
        },
        {
          title: "Type of Law List",
          icon: "mdi-gavel",
          permission: [permission.viewTypeOfLaws],
          link: "/type_of_laws",
        },
        {
          title: "Quickbooks Sync Logs",
          icon: "mdi-clipboard-text",
          permission: [permission.viewQuickbooksSyncLog],
          link: "/quickbooks_sync_logs",
        },
        {
          title: "Billing Tax Settings",
          icon: "mdi-cash",
          permission: [permission.viewBillingTaxSettings],
          link: "/billing_tax_settings",
        },
        {
          title: "Company Settings",
          icon: "mdi-cog",
          permission: [permission.viewCompanySettings],
          link: "/company_settings",
        },
        {
          title: "System Settings",
          icon: "mdi-cog",
          permission: [permission.addEditSystemSettings],
          link: "/system_settings",
        },
      ],
      practiceManagementMenuItems: [
        {
          title: "Contacts & Clients",
          icon: "mdi-card-account-phone-outline",
          permission: [permission.viewContacts],
          link: "/contacts",
        },
        {
          title: "Client Accounts",
          icon: "mdi-card-account-details-outline",
          permission: [permission.viewClientAccount],
          link: "/client_accounts",
        },

        {
          title: "Matters",
          icon: "mdi-folder-file-outline",
          permission: [permission.viewMatters],
          link: "/matters",
        },
        {
          title: "Time Entries",
          icon: "mdi-clipboard-text-clock-outline",
          permission: [permission.viewTimeEntry],
          link: "/time_entries",
        },
        {
          title: "Conflict Search",
          icon: "mdi-clipboard-search-outline",
          permission: [permission.viewConflictSearch],
          link: "/conflict_search_records",
        },
        {
          title: "Disbursements",
          icon: "mdi-cash-sync",
          permission: [permission.viewDisbursement],
          link: "/disbursements",
        },
      ],
      accountingMenuItems: [
        {
          title: "Bills",
          icon: "mdi-clipboard-search-outline",
          permission: [permission.viewMatters],
          link: "/bills",
        },
        {
          title: "Trust Receipts",
          icon: "mdi-content-save",
          permission: [permission.trustManagementGeneral, permission.trustManagmentAdmin],
          link: "/trust_receipts",
        },
        {
          title: "Release Requests",
          icon: "mdi-message-text",
          permission: [permission.requestTrustRelease, permission.trustManagmentAdmin],
          link: "/trust_release_requests",
        },
        {
          title: "Pay Bill From Trust",
          icon: "mdi-credit-card-outline",
          permission: [permission.trustManagmentAdmin],
          link: "/trust_bill_payment",
        },
        {
          title: "Trust Cheques",
          icon: "mdi-cash-multiple",
          permission: [permission.trustReleaseCheque],
          link: "/trust_cheques",
        },
        {
          title: "A/R Trust Cheques",
          icon: "mdi-checkbook",
          permission: [permission.trustManagmentAdmin],
          link: "/ar_trust_cheques",
        },
        {
          title: "General Cheques",
          icon: "mdi-cash-multiple",
          permission: [permission.releaseGeneralCheque],
          link: "/general_cheques",
        },
        {
          title: "Transaction Audit Logs",
          icon: "mdi-file-arrow-left-right-outline",
          permission: [permission.viewAuditedTransaction],
          link: "/transaction_audit_logs",
        },
        {
          title: "Statement Management",
          icon: "mdi-note-text",
          permission: [permission.statementManagement],
          link: "/statement_management",
        },
      ],
      reportMenuItems: [
        {
          title: "Reports",
          icon: "mdi-file-chart",
          permission: [permission.reportsAdmin, permission.viewReports],
          link: "/reports",
        },
      ],
      appBarHeight: 40,
    }
  },
  computed: {
    canViewReport() {
      return this.$store.getters.userHasPermission(permission.reportsAdmin) || this.$store.getters.userHasPermission(permission.viewReports)
    },
    userName() {
      return getCookieData("userName")
    },
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
  methods: {
    filterNoAccessMenuItems(items) {
      return items.filter(item => {
        return (
          item.permission == null ||
          item.permission.filter(permissionItem => {
            return this.$store.getters.userHasPermission(permissionItem)
          }).length > 0
        )
      })
    },
    getAppBarHeight() {
      this.appBarHeight = this.$refs.appBar.clientHeight
    },
    showRolesPermissionsDialog() {
      eventBus.$emit(`${this.routeName}showUserRolesPermissionsDialog`, this.userId)
      eventBus.$emit("showUserRolesPermissionsDialog")
    },
  },
}
</script>
